<template>
  <!-- 资料库>新增资料 -->
  <div class="dict"  v-loading.fullscreen.lock="loadingDetail">
    <div class="form-header-btn">
      <el-page-header
        @back="goBack"
        :content="'运营任务/'+title"
      >
        <template slot="title">
            <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <el-form 
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
      :disabled="$route.query.isEdit == 2"
    >
      <el-form-item label="运营模板：" prop="templateId">
        <el-select class="form-select-class" :disabled="form.publishValue != 0" v-model="form.templateId" @change="changeTemplate" placeholder="请选择">
          <el-option
            v-for="(item, index) in templateList"
            :key="index"
            :label="item.templateName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="接受范围：" prop="orgList">
         <el-cascader
          class="form-select-class"
          :key="keyValue"
          :show-all-levels="false"
          :options="orgList"
          v-model="form.orgList"
          :props="props"
          collapse-tags
          @change="changeOrg"
           :disabled="form.publishValue != 0"
          clearable></el-cascader>
      </el-form-item>
      <el-form-item label="运营主题：" prop="theme">
          <el-input type="text"   :disabled="form.publishValue != 0" v-model.trim="form.theme" placeholder="请输入运营主题"></el-input>
      </el-form-item>
      <el-form-item label="定时发送：" >
        <div class="isScheduleBox">
          <el-switch
          v-model="form.isSchedule"
          active-color="#003685"
           :disabled="form.publishValue != 0"
          inactive-color="#ccc">
        </el-switch>
        <el-form-item v-if="form.isSchedule" label="发布时间：" prop="scheduleTime">
          <el-date-picker
            v-model="form.scheduleTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
             :disabled="form.publishValue != 0"
            placeholder="选择日期">
          </el-date-picker>
      </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="督办人：">
        <el-button type="primary" @click="addAuth">新增</el-button>
        <el-table
          :header-cell-style="{
            background: '#F1F4F9',
            color: '#5E6D9B',
            fontWeight: '400',
            'text-align': 'center',
          }"
          style="
            width: 100%;
            margin-bottom: 1.04167vw;
            margin-top: 1.04167vw;
            min-height: calc(100% - 5.52083vw);
            overflow-y: scroll;
            overflow: visible;
          "
          :data="form.createByAuth"
          border
        >
          <el-table-column prop="userName" label="督办人" width="400">
            <template slot-scope="scope">
              <el-autocomplete type="text" v-model="scope.row.userName" :fetch-suggestions="(val,cb)=>querySearchAsync(val,cb,scope.$index)" @select="(val)=>handleSelect(val,scope.$index)"  placeholder="请输入督办人">
                <template slot-scope="{ item }">
                  <div class="user_name">
                      {{ item.userName }}+{{ item.orgName }}+{{ item.deptName }}
                    <!-- <div class="name">{{ item.userName }}</div>
                    <span class="orgName">{{ item.orgName }}</span>
                    <span class="postName">{{ item.deptName }}</span> -->
                    <!-- <span class="schoolName">{{ item.schoolName }}</span> -->
                  </div>
                </template>
              </el-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="orgName" label="组织" >
          </el-table-column>
          <el-table-column prop="deptName" label="部门">
          </el-table-column>
          <el-table-column  label="操作" width="80" align="center">
            <template slot-scope="scope">
              <el-button @click="delAuth(scope.$index)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <el-divider></el-divider>
      <el-form-item label="模板名称：" prop="">
          <el-input type="text" disabled v-model="form.templateName" ></el-input>
      </el-form-item>
      <el-form-item label="描述：" prop="">
          <el-input type="textarea" disabled :rows="4" v-model="form.templateDesc" ></el-input>
      </el-form-item>
      <el-form-item label="项目数量：" prop="">
          <el-input type="text" disabled v-model="form.totalItem" ></el-input>
      </el-form-item>
      <el-form-item label="总计时长：" prop="">
        <el-input show-word-limit :maxlength="20" disabled v-model="form.totalPredictDay"></el-input>
      </el-form-item>
      <el-form-item v-if="$route.query.isEdit != 2">
        <el-button @click="goBack">返回</el-button>
        <el-button
          type="primary"
          :disabled="uploading"
          :loading="uploading"
          @click="saveAndPublishTask('ruleForm')"
          >发布</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now();
          return time.getTime() < _now - 24*3600000;
        }
      },
      form: {
        templateId: "",
        theme: "",
        isSchedule: false,
        scheduleTime: "",
        orgList: [],
        totalItem:'',
        templateDesc:'',
        totalPredictDay:'',
        templateName:'',
        createByAuth:[]
      },
      loadingDetail:false,
      props: {
        multiple: true,
        value:'label',
        label:'name',
        children:'child'
      },
      uploading: false,
      targeTypeList: [
        {value:1,label:'test01',
          itemList:[
            {
              value:7,
              label:'test04',
            },
            {
              value:8,
              label:'test05',
            },
            {
              value:9,
              label:'test06',
            },
          ]},
        {
          value:2,
          label:'test02',
          itemList:[
            {
              value:4,
              label:'test04',
            },
            {
              value:5,
              label:'test05',
            },
            {
              value:6,
              label:'test06',
            },
          ]
        },
        {value:3,label:'test03'},
      ],
      templateList: [],
      orgList:[],
      rules: {
        templateId: [
          { required: true, message: "请选择运营模板", trigger: "blur" },
        ],
        orgList: [
          { required: true, message: "请选择接收范围", trigger: "blur" },
        ],
        theme: [
          { required: true, message: "请输入运营主题", trigger: "blur" },
        ],
        scheduleTime: [
          { required: true, message: "请选择发布时间", trigger: "blur" },
        ],
      },
      keyValue:1,
      newChooseList:[],
      metaTitle:'',
      labelWidth: '120px',
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
      title:'',
      orgList:[],
      minStartTime:'',
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.initOrg()
    this.getWorkTemplate()
    if(this.$route.query.isEdit == 0){
      // 添加
      this.title = '新增任务'
      if(this.$route.query.id){
        this.changeTemplate(this.$route.query.id)
        this.form.templateId = parseInt(this.$route.query.id)
      }
      this.form.publishValue = ''
      this.metaTitle = '新增运营任务'
    }else if(this.$route.query.isEdit == 1){
      // 编辑
      this.title = '编辑任务'
      this.getTaskInfo()
      this.metaTitle = '编辑运营任务'
    }else if(this.$route.query.isEdit == 2){
      // 查看
      this.title = '查看任务'
      this.getTaskInfo()
      this.metaTitle = '查看运营任务'
    }else if(this.$route.query.isEdit == 3){
      // 复制
      this.title = '复制任务'
      this.getTaskInfo()
      this.metaTitle = '新增运营任务'
    }
    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
    if(chooseMenuInfoList){
      chooseMenuInfoList.map((item) => {
        if(item.path == this.$route.path){
            item.meta.title = this.metaTitle
        }
      })
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList)) 
      breadCrumbList.shift()
      // console.log(breadCrumbList)
      this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
    }
    // this.getThirdtargeTypeList();
  },
  mounted(){
    window.addEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if(window.innerWidth < 1300){
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    querySearchAsync(queryString, cb,index){
      console.log(index);
      if(!queryString){
        cb([])
        return
      }
      this.$api.getUserByName({
        userName:queryString
      }).then(res=>{
        setTimeout(()=>{
          cb(res.data.data)
        },200)
      })
    },
    handleSelect(row,index){
      this.form.createByAuth[index] = row
    },
    // 新增督办人
    addAuth(){
      this.form.createByAuth.push({
        deptLabel: '',
        deptName: '',
        id: '',
        orgName: '',
        postLabel: '',
        postName: '',
        roleName: '',
        schoolLabel: '',
        schoolName: '',
        userId: '',
        userName: '',
        userNo: '',
        userPostLabel: '',
      })
    },
    // 删除督办人
    delAuth(index){
      this.form.createByAuth.splice(index,1)
    },
    getThirdtargeTypeList() {
      this.$api.getThirdDirectory().then((res) => {
        if (res.data.code == 0) {
          this.targeTypeList = res.data.data;
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },
    getWorkTemplate(){
      this.$api.getWorkTemplateList().then(res=>{
        if(res.data.code == '0'){
          this.templateList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initOrg(){
      this.$api.getSchoolForTree().then(res=>{
        if(res.data.code == '0'){
          this.orgList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    changeTemplate(val){
      this.$api.workTemplateEditDetail2(val).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          // this.form = res.data.data;
          this.minStartTime = res.data.data.minStartTime
          this.form.templateDesc = res.data.data.workTemplate.templateDesc
          this.form.templateName = res.data.data.workTemplate.templateName
          this.form.totalPredictDay = res.data.data.workTemplate.totalPredictDay
          this.form.totalItem = res.data.data.workTemplate.totalItem
        } else {
          this.$message.error(res.data.msg);
        }
      });

    },
    changeOrg(val){
      // this.keyValue++
    },
    initChooseOrg(val){
      let chooseArr = val
      let arr = []
      for (let index = 0; index < chooseArr.length; index++) {
        const e = chooseArr[index];
        if(e.length>1){
          arr.push({
            companyLabel:e[0],
            campusLabels:e[1],
          })
          // newArr.map(item=>{
          //   item.companyLabel=e[0],
          //   item.campusLabels.push(e[1])
          // })
        }
      }
      let tempArr = [], newArr = []
      for (let i = 0; i < arr.length; i++) {
        if (tempArr.indexOf(arr[i].companyLabel) === -1) {
          newArr.push({
            companyLabel: arr[i].companyLabel,
            campusLabels: [arr[i].campusLabels]
          })
          tempArr.push(arr[i].companyLabel);
        } else {
          for (let j = 0; j < newArr.length; j++) {
            if (newArr[j].companyLabel == arr[i].companyLabel) {
              newArr[j].campusLabels.push(arr[i].campusLabels)
            }
          }
        }
      }
      return newArr
    },
    getTaskInfo() {
      this.loadingDetail = true
      this.$api.taskListDetail(this.$route.query.id).then((res) => {
        this.loadingDetail = false
        if (res.data.code == 0 && res.data.data) {
          res.data.data.publishValue = res.data.data.isPublish.value
          delete res.data.data.isTerminate
          delete res.data.data.isCustom
          delete res.data.data.isPublish
          if(res.data.data.isSchedule.value == 1){
            res.data.data.isSchedule = true
          }else{
            res.data.data.isSchedule = false
          }
          let arrP = []
          this.form = res.data.data;
          this.form.createByAuth  = JSON.parse(res.data.data.createByAuth)
          this.form.orgList = []
          if(this.form.receivingRangeList){
            this.form.receivingRangeList.forEach(e => {
              if(e.childList){
                e.childList.forEach(ele=>{
                  let arr = [e.orgId,ele.parkId]
                  this.form.orgList.push(arr)
                })
              }
            });
          }
          console.log('7788ss',this.form);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    saveAndPublishTask(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            let parmas = JSON.parse(JSON.stringify(this.form));
            parmas.orgList = this.initChooseOrg(parmas.orgList)
            let arr = []
            arr = this.form.createByAuth.filter(e=> {
              if(e.userName){
                return e
              }
            })
            parmas.createByAuth = JSON.stringify(arr)
            let timestamp = null
            if(parmas.isSchedule){
              parmas.isSchedule = 1
              timestamp = new Date(parmas.scheduleTime).getTime()
            }else{
              parmas.isSchedule = 0
              timestamp = (new Date()).valueOf();
            }
            // timestamp 当前时间    minStartTime最小开始时间
            console.log(timestamp>new Date(this.minStartTime))
            // 
            if(timestamp>new Date(this.minStartTime).getTime()){
              const h = this.$createElement;
              this.$confirm(
                h("p", null, [
                  h(
                    "span",
                    {
                      style:
                        "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
                    },
                    // "确定要删除该条数据？"
                  ),
                  h(
                    "div",
                    {
                      style:
                        "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
                    },
                    "选中的模板中有项目开始时间早于发布时间"
                  ),
                ]),
                "发布失败",
                {
                  type: "warning",
                  iconClass: "el-icon-circle-close",
                  closeOnClickModal: false,
                  customClass: "warningMessage",
                  confirmButtonText: '修改模板',
                  cancelButtonText: '取消',
                }
              ).then(() => {
                this.$router.push({
                  path:'/editOperatingTemplate',
                  query:{
                    isEdit:1,
                    id:parmas.templateId
                  }
                })
              })
            }else{
              this.uploading = true
              if(this.$route.query.isEdit == 1){
                this.$api.taskListEdit(parmas).then((res) => {
                  if (res.data.code == 0) {
                    this.$message({
                      type: "success",
                      message: "保存成功",
                    });
                    this.goBack();
                  } else {
                    this.$message.error(res.data.msg || "保存失败");
                  }
                  this.uploading = false
                });
              }else{
                this.$api.taskListAdd(parmas).then((res) => {
                  if (res.data.code == 0) {
                    this.$message({
                      type: "success",
                      message: "保存成功",
                    });
                    this.goBack();
                  } else {
                    this.$message.error(res.data.msg || "保存失败");
                  }
                  this.uploading = false
                });
              }
            }
        } else {
          this.$message.error("请完善信息");
          return false;
        }
      });
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
  .isScheduleBox{
    min-height: 40px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
}
.el-autocomplete{
  width: 100%;
}
.user_name{
  display: flex;
  justify-content: space-between;
  .name,.postName,.orgName{
    width: 33.33%;
  }
  // .schoolName{
  //   width: 30em;
  // }
  // .postName{
  //   width: 50%;
  // }
}
</style>

<style>
.warningMessage .el-button{
  width: 98px;
  /* height: 38px; */
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>